import { useStoreState } from "easy-peasy";
import Cookies from "universal-cookie";

export const services = {
  // backendUrl: process.env.REACT_APP_BACKEND_URL,
  frontendUrl: process.env.REACT_APP_FRONTEND_URL,
};

export const appSettings = {
  ignoreSalePermissions: JSON.parse(
    process.env.REACT_APP_IGNORE_SALE_PERMISSIONS
  ),
  isBatchStandard: JSON.parse(process.env.REACT_APP_BATCH_STANDARD),
  adminOnly: JSON.parse(process.env.REACT_APP_ADMIN_ONLY_AUTH),
  requireSalesRep: JSON.parse(process.env.REACT_APP_SALE_REP),
  isGigcOrLocal: [
    "https://gigc.invexonline.com",
    "http://localhost:3000",
  ].includes(services.frontendUrl),
  isGigc: "https://gigc.invexonline.com" === services.frontendUrl,
  isBarrelsAndCrates:
    "https://barrels-crates.invexonline.com" === services.frontendUrl,
  printerType: process.env?.REACT_APP_PRINTER
    ? process.env?.REACT_APP_PRINTER
    : "A4 Size Printer",
  isThermalPrinter: process.env?.REACT_APP_PRINTER === "Thermal Printer",
  hasSize: process.env?.REACT_APP_HAS_SIZE
    ? JSON.parse(process.env?.REACT_APP_HAS_SIZE)
    : false,
  hasIncentive: process.env?.REACT_APP_HAS_INSENTIVE
    ? JSON.parse(process.env?.REACT_APP_HAS_INSENTIVE)
    : false,
  isMertesacker: "https://mertesacker.invexonline.com" === services.frontendUrl,
  storeBaseUrl: process.env?.REACT_APP_STORE_BASE_URL,
  isMedbury: true /*"=https://riverscms.medpharma.io" === services.frontendUrl,*/,
  isHydroferric: services.frontendUrl.includes("hydroferric"),
};

// Gigc / Local - the firsthy36
/* export const backendApis = [
  {
    url: process.env.REACT_APP_CEMENT_BACKEND_URL,
    name: "Dangote Cement",
    port: "6002",
    // viewOnly: true,
  },
  {
    url: process.env.REACT_APP_CEMENT_BUA_BACKEND_URL,
    name: "Bua Cement",
    port: "6002",
  },
  {
    url: process.env.REACT_APP_IRON_RODS_BACKEND_URL,
    name: "Iron Rods",
    port: "6002",
    isStore: true,
    isHq: true,
  },
  {
    url: process.env.REACT_APP_IRON_RODS_AKURE_BACKEND_URL,
    name: "Iron Rods - Akure",
    port: "7000",
    // viewOnly: true,
  },
  //  {
  //   url: "https://gigc-rod-test-api.invexonline.com",
  //   name: "Iron Rods - Test",
  //   port: "6106",
  //   // viewOnly: true,
  // },
]; */

/* export const backendApis = [
  {
    url: process.env.REACT_APP_IRON_RODS_BACKEND_URL,
    name: "Invex",
    port: "6002",
    isStore: true,
    isHq: true,
  },
]; */

// export const backendApis = [
//   {
//     url: process.env.REACT_APP_IRON_RODS_BACKEND_URL,
//     name: "Invex",
//     port: "6002",
//     isStore: true,
//     isHq: true,
//   },
// ];

// complete TEST ============================
/* export const backendApis = [
  {
    url: "https://invex01dev.invexonline.com",
    name: "INVEX",
    port: "6002",
  },
  {
    url: process.env.REACT_APP_CEMENT_BACKEND_URL,
    name: "Dangote Cement",
    port: "6002",
    isStore: true,
  },
  {
    url: process.env.REACT_APP_CEMENT_BUA_BACKEND_URL,
    name: "Bua Cement",
    port: "6002",
  },
]; */

/* export const backendApis = [
  {
    url: "https://neu-stream-api.invexonline.com",
    name: "NEU STREAM",
    port: "6025",
  },
]; */

// Test
// export const backendApis = [
//   {
//     url: "https://invex01dev.invexonline.com",
//     name: "Showroom",
//     port: "6023",
//   },
//   {
//     url: "https://gigc-test-api.invexonline.com",
//     name: "Warehouse",
//     port: "6006",
//   },
//   {
//     url: process.env.REACT_APP_CEMENT_BACKEND_URL,
//     name: "Dangote Cement",
//     port: "6001",
//   },
// ];
/*export const backendApis = [
  //   {
  //   url: "https://invex01dev.invexonline.com",
  //   name: "Showroom",
  //   port: "6023",
  // },
  // {
  //   url: "https://gigc-test-api.invexonline.com",
  //   name: "Warehouse",
  //   port: "6006",
  //   isStore: true,
  // },
  // {
  //   url: process.env.REACT_APP_CEMENT_BACKEND_URL,
  //   name: "Dangote Cement",
  //   port: "6001",
  // },
  {
    url: "https://gigc-test-api.invexonline.com",
    name: "Central Warehouse",
    port: "6006",
    isStore: true,
  },
  {
    url: "https://invex01dev.invexonline.com",
    name: "Lagos Sales Outlet",
    port: "6023",
  },
  // {
  //   url: process.env.REACT_APP_CEMENT_BACKEND_URL,
  //   name: "Warri Sales Outlet",
  //   port: "6001",
  // },
];*/

/* export const backendApis = [
  {
    url: "https://invex01dev.invexonline.com",
    name: "Showroom",
    port: "6023",
  },
  {
    url: "https://gigc-test-api.invexonline.com",
    name: "Warehouse",
    port: "6006",
    isStore: true,
  },
  // {
  //   url: process.env.REACT_APP_CEMENT_BACKEND_URL,
  //   name: "Dangote Cement",
  //   port: "6001",
  // },
]; */

/* {
    url: "https://invex01invex.invexonline.com",
    name: "Live Test",
    port: "6024",
  }, 
];*/

/*  {
    url: process.env.REACT_APP_TRANSPORT_BACKEND_URL,
    name: "Transport and Logistics",
  },
  {
    url: process.env.REACT_APP_OIL_BACKEND_URL,
    name: "Oil and Gas",
  },
  {
    url: "",
    name: "CrystalWire Industries",
  },
  {
    url: "",
    name: "GIGC Holdings",
  },*/

// hello energy
/* export const backendApis = [
  {
    url: "https://invex01creeklpg.invexonline.com",
    name: "Creek LPG Accessory",
    port: "6011",
  },
  {
    url: "https://invex01creeklubricant.invexonline.com",
    name: "Creek Lubricant",
    port: "6012",
  },
  {
    url: "https://invex01omagbalpg.invexonline.com",
    name: "Omagba LPG Accessory",
    port: "6013",
  },
  {
    url: "https://invex01omagbalubricant.invexonline.com",
    // name: "Omagba Lubricant",
    name: "Hello Energy Mart",
    port: "6014",
  },
  {
    url: "https://invex01nkwellelpg.invexonline.com",
    name: "Nkwelle LPG Accessory",
    port: "6015",
  },
  {
    url: "https://invex01nkwellelubricant.invexonline.com",
    name: "Nkwelle Lubricant",
    port: "6016",
  },
  {
    url: "https://invex01nkwelleprovision.invexonline.com",
    name: "Nkwelle Provision",
    port: "6017",
  },
  {
    url: "https://invex01lpgstore.invexonline.com",
    name: "LPG Warehouse",
    port: "6018",
  },
  {
    url: "https://invex01lubricantstore.invexonline.com",
    name: "Lubricant Warehouse",
    port: "6019",
  },
]; */

// mertersacker
/* export const backendApis = [
  {
    url: "https://invex01mertesackerph.invexonline.com",
    name: "MERTESACKER-PH",
    port: "6020",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://invex01mertesackerbayelsa.invexonline.com",
    name: "MERTESACKER-BAYELSA",
    port: "6021",
  },
  {
    url: "https://giano-betterland.invexonline.com",
    name: "GIANO BETTER LAND",
    port: "6071",
  },
  {
    url: "https://giano-market.invexonline.com",
    name: "GIANO MARKET",
    port: "6072",
  },
]; */

// White Gold
/* export const backendApis = [
  {
    url: "https://adminwhitegold.invexonline.com",
    name: "WHITEGOLD",
    port: "6026",
  },
] */

// Brave
/* export const backendApis = [
  {
    url: "https://adminbravewarehouse.invexonline.com",
    name: "Central Warehouse",
    port: "6031",
    isHq: true,
    isStore: true,
  },
  //   {
  //   url: "https://adminbraveairforce.invexonline.com",
  //   name: "Airforce",
  //   port: "6027",
  //   isStore: true,
  // },
  // {
  //   url: "https://adminbravenkpolu.invexonline.com",
  //   name: "Nkpolu",
  //   port: "6028",
  // },
  // {
  //   url: "https://adminbraveoyigbo.invexonline.com",
  //   name: "Oyigbo",
  //   port: "6029",
  // },
  // {
  //   url: "https://adminbravetransamadi.invexonline.com",
  //   name: "Trans-Amadi",
  //   port: "6030",
  // },
  // {
  //   url: "https://adminbrave242warehouse.invexonline.com",
  //   name: "242_Warehouse",
  //   port: "6032",
  // },
]; */

// Stuch
/* export const backendApis = [
  {
    url: "https://adminstuch.invexonline.com",
    name: "STUCH",
    port: "6033",
  },
]; */

// white gold
/* export const backendApis = [
  {
    url: "https://adminwhitegold.invexonline.com",
    name: "WHITEGOLD",
    port: "6034",
  },
]; */

// Gnash
/* export const backendApis = [
  {
    url: "https://admingnash.invexonline.com",
    name: "GNASH",
    port: "6035",
  },
]; */

//Halfort
/* export const backendApis = [
  {
    url: "https://halfortheadquaters.invexonline.com",
    name: "HEADQUATERS",
    port: "6036",
  },
  {
    url: "https://halfortmushin.invexonline.com",
    name: "MUSHIN",
    port: "6037",
  },
  {
    url: "https://halfortketu.invexonline.com",
    name: "KETU",
    port: "6038",
  },
  {
    url: "https://halfortikotun.invexonline.com",
    name: "IKOTUN",
    port: "6039",
  },
  {
    url: "https://halfortboundary.invexonline.com",
    name: "BOUNDARY",
    port: "6040",
  },
  {
    url: "https://halfortmowe.invexonline.com",
    name: "MOWE",
    port: "6041",
  },
]; */

/* export const backendApis = [
  {
    url: "https://barrels-crates-api.invexonline.com",
    name: "SALES OUTLET",
    port: "6042",
  },
  {
    url: "https://barrels-crates-warehouse-api.invexonline.com",
    name: "WAREHOUSE",
    port: "6045",
  },
]; */

//--  test-hr-api - 6043
/* export const backendApis = [
  {
    url: "https://dewlite-api.invexonline.com",
    name: "DEWLITE",
    port: "6044",
  },
]; */

/* export const backendApis = [
  {
    url: "https://tonyglobal-api.invexonline.com",
    name: "Tony global ind Ltd",
    port: "6046",
  },
]; */
//--  mertesacker-hr-api - 6048
//--  gigc-hr-api - 6049
/* export const backendApis = [
  {
    url: "https://invex01dev.invexonline.com",
    name: "MEDBURY HQ (TEST)",
    port: "6081",
    isHq: true,
  },
  {
    url: "https://test-service-api.invexonline.com",
    name: "Service",
    port: "6047",
  },
  {
    url: "https://rivers-cms-api.invexonline.com",
    name: "RIVERS CMS (TEST)",
    port: "6082",
    isStore: true,
  },
  {
    url: "https://shell-api.invexonline.com",
    name: "SHELL (TEST)",
    port: "6083",
  },
]; */

/* export const backendApis = [
  {
    url: "https://juesco-api.invexerp.io",
    name: "JUESCO",
    port: "6050",
  },
]; */

/* export const backendApis = [
  {
    url: "https://boshsystems-api.invexerp.io",
    name: "BOSH SYSTEMS",
    port: "6092",
  },
]; */

/* export const backendApis = [
  {
    url: "https://boshsystems-api.invexerp.io",
    name: "TEST",
    port: "6092",
  },
]; */

// https://boshsystems-hr-api.invexerp.io - 6093

/* export const backendApis = [
  {
    url: "https://hydroferric-api.invexerp.io",
    name: "HYDROFERRIC",
    port: "6095",
  },
]; */

// https://hydroferric-api.invexerp.io - 6096

/* export const backendApis = [
  {
    url: "https://eb-api.invexerp.io",
    name: "Excellentbridge",
    port: "6094",
  },
]; */

//  --  juesco-hr-api - 6051
/* export const backendApis = [
  {
    url: "https://petrobase-api.invexerp.io",
    name: "PETROBASE",
    port: "6052",
  },
]; */
//  --  petrobase-hr-api - 6053

/* 
{
    url: "https://farezfarm-export-api.invexonline.com",
    name: "Export Unit",
  },
*/

/* export const backendApis = [
  {
    url: "https://rosebel-api.invexonline.com",
    name: "ROSEBEL",
    port: "6055",
    isHq: true,
    isStore: true,
  },
  {
    url: "https://rosebel-food-api.invexonline.com",
    name: "FOOD ITEMS",
    port: "6062",
  },
]; */

/*export const backendApis = [
  {
    url: "https://juesco-api.invexerp.io",
    name: "JUESCO",
    port: "6050",
},
]; */

// old rivercms
/*
 {
    url: "https://api.riverscms.com",
    name: "RIVERS CMS",
    port: "6056",
    isStore: true,
  },
*/

//  --  juesco-hr-api - 6051
// RIVERS CMS
export const backendApis = [
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
    isStore: true,
  },
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    viewOnly: true,
    isHq: true,
  },
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
    viewOnly: true,
  },
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
    viewOnly: true,
  },
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
    viewOnly: true,
  },
  {
    url: "https://rjolad-api.medpharma.io",
    name: "RJOLAD",
    port: "6117",
    viewOnly: true,
  },
];

// LILY
/* export const backendApis = [
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
    isStore: true,
  },
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    viewOnly: true,
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
    viewOnly: true,
  },
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
    viewOnly: true,
  },
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
    viewOnly: true,
  },
]; */

// SHELL
/* export const backendApis = [
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
    isStore: true,
  },
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    viewOnly: true,
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
    viewOnly: true,
  },
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
    viewOnly: true,
  },
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
    viewOnly: true,
  },
]; */

// RJOLAD
/* export const backendApis = [
  {
    url: "https://rjolad-api.medpharma.io",
    name: "RJOLAD",
    port: "6117",
    isStore: true,
  },
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
    viewOnly: true,
  },
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    viewOnly: true,
    isHq: true,
  },
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
    viewOnly: true,
  },
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
    viewOnly: true,
  },
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
    viewOnly: true,
  },
]; */

/* export const backendApis = [
  {
    url: "https://basic-api.invexonline.com",
    name: "BASIC SALES POINT",
    port: "6075",
  },
  {
    url: "https://warehouse-api.invexonline.com",
    name: "HQ WAREHOUSE",
    port: "6076",
  },
]; */

/* export const backendApis = [
  {
    url: "https://tracevision-api.invexone.com",
    name: "TRACEVISION SHOP",
    port: "6080",
    parentCompany: "HIKVISION",
  },
  {
    url: "https://tracevision-wh-api.invexone.com",
    name: "TRACEVISION WAREHOUSE",
    port: "6084",
    parentCompany: "HIKVISION",
  },
]; */

/* export const backendApis = [
  {
    url: "https://cecallis-api.invexone.com",
    name: "CECALLIS SHOP",
    port: "6080",
    parentCompany: "HIKVISION",
  },
  {
    url: "https://cecallis-wh-api.invexone.com",
    name: "CECALLIS WAREHOUSE",
    port: "6085",
    parentCompany: "HIKVISION",
  },
]; */

/* export const backendApis = [
  {
    url: "https://ceesat-api.invexone.com",
    name: "CEESAT SHOP",
    port: "6087",
    parentCompany: "HIKVISION",
  },
  {
    url: "https://ceesat-wh-api.invexone.com",
    name: "CEESAT WAREHOUSE",
    port: "6088",
    parentCompany: "HIKVISION",
  },
]; */

/* export const backendApis = [
  {
    url: "https://hikwa-api.invexone.com",
    name: "HIKWA SHOP",
    port: "6089",
    parentCompany: "HIKVISION",
  },
  {
    url: "https://hikwa-wh-api.invexone.com",
    name: "HIKWA WAREHOUSE",
    port: "6090",
    parentCompany: "HIKVISION",
  },
]; */

/* export const backendApis = [
  {
    url: "https://invex01dev.invexonline.com",
    name: "MEDBURY HQ",
    port: "6081",
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.invexonline.com",
    name: "RIVERS CMS",
    port: "6082",
  },
  {
    url: "https://shell-api.invexonline.com",
    name: "SHELL",
    port: "6083",
  },
]; */

/* export const backendApis = [
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.invexonline.com",
    name: "RIVERS CMS",
    port: "6082",
    viewOnly: true,
  },
  {
    url: "https://shell-api.invexonline.com",
    name: "SHELL",
    port: "6083",
    viewOnly: true,
  },
]; */

// MEDBURY HQ
/* export const backendApis = [
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
  },
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
  },
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
  },
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
  },
  {
    url: "https://rjolad-api.medpharma.io",
    name: "RJOLAD",
    port: "6117",
  },
]; */

/* export const backendApis = [
  {
    url: "https://eli-api.invexone.com",
    name: "ELI",
    port: "6086",
  },
]; */

/* export const backendApis = [
  {
    url: "https://farezfarm-aggregating-api.invexonline.com",
    name: "Aggregating Unit",
    port: "6057",
    isStore: true,
  },
  {
    url: "https://farezfarm-commodity-api.invexonline.com",
    name: "Commodity Unit",
    port: "6058",
  },
  //   {
  //   url: "https://farezfarm-export-api.invexonline.com",
  //   name: "Export Unit",
  //   port: "6059",
  // },
  {
    url: "https://farezfarm-farm-api.invexonline.com",
    name: "Farm Unit",
    port: "6060",
  },
  {
    url: "https://farezfarm-processing-api.invexonline.com",
    name: "Processing Unit",
    port: "6061",
  },
]; */

/* export const backendApis = [
  {
    url: "https://chysel-api.invexonline.com",
    name: "CHYSEL",
    port: "6063",
    isStore: true,
  },
];
 */

/* export const backendApis = [
  {
    url: "https://moreplex-api.invexonline.com",
    name: "MOREPLEX TELEVISION LIMITED",
    port: "6064",
    isStore: true,
  },
  {
    url: "https://buzz-broadcasting-ltd.invexonline.com",
    name: "BUZZ BROADCASTING LTD",
    port: "6065",
  },
  {
    url: "https://iso-care-ltd.invexonline.com",
    name: "ISO-CARE LTD",
    port: "6066",
  },
  {
    url: "https://mimaya-ltd.invexonline.com",
    name: "MIMAYA LIMITED",
    port: "6067",
  },
  {
    url: "https://naturessence-usa-ng.invexonline.com",
    name: "NATURESSENCE USA - Nigeria",
    port: "6068",
  },
  {
    url: "https://naturessence-usa-usa.invexonline.com",
    name: "NATURESSENCE USA - USA",
    port: "6069",
  },
]; */

/* export const backendApis = [
  {
    url: "https://suits-abuja-api.invexone.com",
    name: "ABUJA",
    port: "6100",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://suits-lekki-api.invexone.com",
    name: "LEKKI",
    port: "6098",
  },
]; */

/* export const backendApis = [
  {
    url: "https://lordtech-api.invexerp.io",
    name: "LORDTECH",
    port: "6101",
    isStore: true,
    isHq: true,
  },
];
 */

// EDO LIVE
/* export const backendApis = [
  {
    url: "https://edo-api.medpharma.io",
    name: "EDO",
    port: "6102",
    isStore: true,
  },
  {
    url: "https://hq-api.medpharma.io",
    name: "MEDBURY HQ",
    port: "6081",
    viewOnly: true,
    isHq: true,
  },
  {
    url: "https://rivers-cms-api.medpharma.io",
    name: "RIVERS CMS",
    port: "6056",
    viewOnly: true,
  },
  {
    url: "https://lily-api.medpharma.io",
    name: "LILY",
    port: "6073",
    viewOnly: true,
  },
  {
    url: "https://shell-api.medpharma.io",
    name: "SHELL",
    port: "6074",
    viewOnly: true,
  },
  {
    url: "https://rjolad-api.medpharma.io",
    name: "RJOLAD",
    port: "6117",
    viewOnly: true,
  },
]; */

/* export const backendApis = [
  {
    url: "https://apex-api.invexone.com",
    name: "APEX",
    port: "6103",
    isStore: true,
  },
];
 */

// eb-hr-api.invexerp.io - 6103

/* export const backendApis = [
  {
    url: "https://sitsl-api.invexerp.io",
    name: "SITSL",
    port: "6105",
    isStore: true,
  },
]; */

// sitsl-hr-api.invexerp.io - 6107

/* export const backendApis = [
  {
    url: "https://valintegs-api.invexerp.io",
    name: "VALINTEGS",
    port: "6109",
    isStore: true,
  },
]; */

// valintegs-hr-api.invexerp.io - 6110

/* export const backendApis = [
  {
    url: "https://obiekwe-rumubiakani-api.invexone.com",
    name: "Rumubiakani",
    port: "6111",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://obiekwe-oyigbo-api.invexone.com",
    name: "Oyigbo",
    port: "6112",
  },
  {
    url: "https://obiekwe-akpajo-api.invexone.com",
    name: "Akpajo",
    port: "6113",
  },
  {
    url: "https://obiekwe-thermocool-api.invexone.com",
    name: "Thermocool",
    port: "6114",
  },
]; */

/* export const backendApis = [
  {
    url: "https://lumen-rukpokwu-api.invexone.com",
    name: "Rukpokwu",
    port: "6115",
    isStore: true,
    isHq: true,
  },
  {
    url: "https://lumen-eneka-api.invexone.com",
    name: "Eneka",
    port: "6116",
  },
]; */

export function useReportsNavItems() {
  // const generalSettings = useStoreState((state) => state.generalSettings);

  return [
    /* {
    name: "Cash book",
    to: "cash-book",
  }, */
    {
      name: "Sales Analysis",
      to: "sales-analysis",
    },
    {
      name: "Incentive Report",
      to: "sales-incentive",
    },
    {
      name: "Sales Rep Reports",
      to: "sales-rep-reports/sales",
    },
    {
      name: "Sale by Invoice",
      to: "sale-by-invoice",
    },
    {
      name: "Invoices",
      to: "invoices",
    },
    {
      name: "Cash Book",
      to: "payment-report",
    },
    {
      name: "Cash/Bank Balances",
      to: "cash-bank-balances",
    },
    /* {
    name: "Transaction Analysis",
    to: "transaction-analysis",
  },
  {
    name: "Customer Transactions Analysis",
    to: "customer-transactions-analysis",
  }, */
    {
      name: "Customer Ledger",
      to: "customer-ledger",
    },
    /*  {
    name: "Loyalty Customers",
    to: "loyalty-customers",
  }, */
    {
      name: "Accounts Receivables",
      to: "accounts-recievables",
    },
    {
      name: "Accounts Receivables Aging Report",
      to: "accounts-recievables-aging-report",
    },
    {
      name: "Accounts Payables",
      to: "accounts-payables",
    },
    {
      name: "Accounts Payable Aging Report",
      to: "accounts-payables-aging-report",
    },
    {
      name: "Purchase History",
      to: "purchase-history",
    },
    /*
  {
    name: "Stock Position by Date",
    to: "stock-position-by-date",
  }, */

    {
      name: "Inventory Analysis",
      to: "inventory-analysis",
    },

    /* {
    name: "Inventory Analysis(Warehouse)",
    to: "inventory-analysis-warehouse",
  },

  {
    name: "Print Inventory By Product ",
    to: "print-inventory-by-product",
  },

  {
    name: "Vendors Payment History",
    to: "vendors-payment-history",
  },

  {
    name: "Vendors Ledger",
    to: "vendors-ledger",
  }, */

    {
      name: "Expenses",
      to: "expenses",
    },
    {
      name: "Imprest Account",
      to: "imprest-account",
    },

    {
      name: "Stock Monitor",
      to: "stock-records",
    },

    {
      name: "Job/Contract Report",
      to: "job-income-statement",
    },
    {
      name: "Employee Advances",
      to: "employee-advances",
    },
    {
      name: "Financials",
      grandchildRoutes: [
        {
          name: "General Ledger",
          to: "reports/general-ledger",
        },
        {
          name: "Trial Balance",
          to: "reports/trial-balance",
        },
        {
          name: "Income Statement",
          to: "reports/income-statement",
        },

        {
          name: "Balance Sheet",
          to: "reports/balance-sheet",
        },
      ],
    },

    /*
  {
    name: "Bank Account Analysis",
    to: "bank-account-analysis",
  },


 */
  ];
}

export const salesUserReportNavItems = [
  {
    name: "Sales Analysis",
    to: "sales-analysis",
  },
  {
    name: "Sale by Invoice",
    to: "sale-by-invoice",
  },
  {
    name: "Cash Book",
    to: "payment-report",
  },
  {
    name: "Accounts Receivables",
    to: "accounts-recievables",
  },
  {
    name: "Customer Ledger",
    to: "customer-ledger",
  },

  {
    name: "Purchase History",
    to: "purchase-history",
    isCementNav: true,
  },
  {
    name: "Stock Monitor",
    to: "stock-records",
    isCementNav: true,
  },
  {
    name: "Sales Rep Reports",
    to: "sales-rep-reports/sales",
  },
];

export const storeUserReportNavItems = [
  {
    name: "Purchase History",
    to: "purchase-history",
  },
  {
    name: "Inventory Analysis",
    to: "inventory-analysis",
  },
  {
    name: "Stock Monitor",
    to: "stock-records",
  },
  {
    name: "Sales Analysis",
    to: "sales-analysis",
  },
  {
    name: "Sales Rep Reports",
    to: "sales-rep-reports/sales",
  },
];

export const durationOptions = [
  {
    value: "Day",
    label: "Day",
  },
  {
    value: "Week",
    label: "Week",
  },
  {
    value: "Month",
    label: "Month",
  },
];

export const supplyOptions = [
  { value: "Not Supplied", label: "Not Supplied" },
  {
    value: "Partial Supply",
    label: "Partial Supply",
  },
  {
    value: "Supplied",
    label: "Supplied",
  },
  { value: "Pending Delivery", label: "Pending Delivery" },
  {
    value: "Loaded & On Transit",
    label: "Loaded & On Transit",
  },
  {
    value: "Delivered & Closed",
    label: "Delivered & Closed",
  },
];

export const supplyOptionsMin = [
  { value: "Not Supplied", label: "Not Supplied" },
  {
    value: "Supplied",
    label: "Supplied",
  },
];

export const supplyOptionsParams = [
  {
    value: "Not Supplied & Partial Supply",
    label: "Not Supplied & Partial Supply",
  },
  { value: "Not Supplied", label: "Not Supplied" },
  {
    value: "Partial Supply",
    label: "Partial Supply",
  },
  {
    value: "Supplied",
    label: "Supplied",
  },
  { value: "Pending Delivery", label: "Pending Delivery" },
  {
    value: "Loaded & On Transit",
    label: "Loaded & On Transit",
  },
  {
    value: "Delivered & Closed",
    label: "Delivered & Closed",
  },
];

export const wareHouseChildren = [
  {
    name: "Main Asset Register",
    to: "main-asset-register",
  },
  {
    name: "Warehouse",
    to: "warehouse",
  },
  {
    name: "Storage Location ",
    to: "storage-location",
  },
];

export const maintanceChildren = [
  {
    name: "All Asset",
    to: "maintenance/all-asset",
  },
  {
    name: "Upcoming",
    to: "maintenance/Upcoming",
  },
  {
    name: "Ongoing",
    to: "maintenance/Ongoing",
  },
  {
    name: "History",
    to: "maintenance/history",
  },
];

export const vendorManagement = [
  {
    name: "Vendors",
    to: "vendor/vendors",
  },
  // {
  //   name: "Verify Vendors",
  //   to: "vendor/Verify-Vendors",
  // },
  {
    name: "Vendors Ledger",
    to: "vendor/vendors-ledger",
  },
  {
    name: "Pay a Vendor",
    to: "vendor/post-vendors-payment",
  },
  {
    name: "Vendors Credit/Debit Memo",
    to: "vendor/creadit-debit-memo",
  },
  {
    name: "Create Memo",
    to: "vendor/create-vendors-memo",
  },
  {
    name: "Advance Search",
    to: "vendor/vendor-advance",
  },
  {
    name: "Request For Quotes",
    to: "vendor/company-rfq",
  },
  {
    name: "Pending Quotes",
    to: "vendor/pending-quotes",
  },
  {
    name: "Approved Quotes",
    to: "vendor/approved-quotes",
  },
  {
    name: "Purchase Order",
    to: "vendor/PO",
  },
  {
    name: "Routing PO",
    to: "vendor/routing-PO",
  },
  {
    name: "Create Invoice",
    to: "vendor/create-vendors-invoice",
  },
  {
    name: "Upcoming Payments",
    to: "vendor/upcoming-payments",
  },
  {
    name: "Approve Stock Inbound",
    to: "approval/vendor-invoice",
  },
  {
    name: "ATC Payment Report",
    to: "vendor/atc-payment-report",
    isCementNav: true,
  },
  {
    name: "Edit Vendor Payment",
    to: "vendor/replace-vendor-payment",
  },
  {
    name: "Delete Vendor Payment",
    to: "vendor/delete-vendor-payment",
  },
  {
    name: "Suppliers Performance",
    to: "vendor/supply-rate",
  },
  {
    name: "Draft Purchase Order",
    to: "vendor/draft-PO",
  },
  {
    name: "Vendor Category",
    to: "vendor/category",
  },
  {
    name: "Supplier Vendor Evaluation",
    to: "vendor/supplier-vendor-evaluation",
  },
  {
    name: "Post Supplier Vendor Evaluation",
    to: "vendor/post-supplier-evaluation",
  },
  {
    name: "Evaluation Field",
    to: "vendor/supplier-evaluation-items",
  },
];

export const salesSupplySubNav = [
  {
    name: "Sales",
    to: "sales",
  },
  {
    name: "Approved Proforma",
    to: "proforma",
  },
  {
    name: "Pre Order",
    to: "pre-order",
  },
];

export const HRBackEndUrl = process.env.REACT_APP_HR_BACKEND;
export const HRFrontEndUrl = process.env.REACT_APP_HR_FRONTEND;
export const AdvanceVendor = JSON.parse(process.env.REACT_APP_ADVANCE_VENDOR)
  ? true
  : false;

export const IS_HR = process.env.REACT_APP_IS_HR
  ? JSON.parse(process.env.REACT_APP_IS_HR)
  : false;

export const setCookies = (responseJson) => {
  let domain = process.env.REACT_APP_FRONTEND_URL.includes("localhost")
    ? null
    : process.env.REACT_APP_INVEX_DOMAIN;
  console.log(responseJson);
  if (responseJson.user) {
    const expiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const cookies = new Cookies();
    cookies.set("isLoggedIn", true, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("userId", responseJson.user._id, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("userId", responseJson.user._id, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("token", responseJson.user.token, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("isFirstTime", responseJson.user.isFirstTime, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("branch_id", responseJson.branch, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set(
      "company_id",
      responseJson.user.company_id
        ? responseJson.user.company_id
        : process.env?.REACT_APP_HR_COMPANY,
      {
        path: "/",
        expires: expiryDate,
        ...(domain ? { domain } : {}),
      }
    );

    if (responseJson.user.isAdmin || responseJson.user.isSuperAdmin) {
      if (responseJson.user.isSuperAdmin) {
        cookies.set("isSuperAdmin", true, {
          path: "/",
          expires: expiryDate,
          ...(domain ? { domain } : {}),
        });
      } else if (responseJson.user.isAdmin) {
        cookies.set("isAdmin", true, {
          path: "/",
          expires: expiryDate,
          ...(domain ? { domain } : {}),
        });
      }
    }
  }
};

const cookies = new Cookies();

export const collabOptions = (data = { childRoutesName: "childRoutes" }) => {
  return {
    name: "Collaboration Tools",
    [data.childRoutesName]: [
      {
        name: "Cloud Drive",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }`,
      },

      {
        name: "Projects",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_COLLABORATION_DOMAIN
        }`,
      },
      {
        name: "Tasks",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_COLLABORATION_DOMAIN
        }`,
      },

      {
        name: "Conversations",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_APP_DOMAIN
        }`,
      },
      {
        name: "Schedules",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_APP_DOMAIN
        }`,
      },
      {
        name: "Meetings",
        to: `${
          process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN
        }/api/login?t=${cookies.get("fextoken")}&redirect=${
          process.env.REACT_APP_FEXSPACE_APP_DOMAIN
        }`,
      },
    ],
  };
};
export function getQueryParams() {
  let url = new URL(document.location.href);
  url = url.searchParams.entries();
  const result = {};
  for (const [key, value] of url) {
    result[key] = value;
  }

  return result;
}
